import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import './Active.css';
const ActiveAccountComponent = () =>{

    const [active, setActive] = useState<string>("s");

    const {uuid, secret} = useParams();

    const activeAccount = async () => {

        await fetch('https://api.picsure.pl/auth/active?u=' + uuid + "&s=" + secret, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if(response.ok){
                setActive("Twoje konto zostało aktywowane!");
            }else{
                setActive("Ups... link aktywcyjny jest niepoprawny.");
            }
        }).catch(error => {
            setActive("Ups... link aktywcyjny jest niepoprawny.");
        });

    }

    useEffect(() => {
            activeAccount();
    }, []);



        return(
            <>
                <div className="test">
                    <h2>{active}</h2>
                    {active==='Twoje konto zostało aktywowane!' ? <h4>Możesz zamknąć to okno i zalogować się do aplikacji.</h4> : ""}
                </div>
            </>
        )


}

export default ActiveAccountComponent;