import "./App.css";

const MainPage = () => {
    return (
        <div className={"background"}>
            <div className="center">
                <div className="logo">
                    <img src="https://picsure.pl/imgs/picsure.png" alt=""/>
                </div>

                <div className="appName"> Pic<span className="purpleTxt">Sure</span> </div>
            </div>

            <div className="line"></div>

            <div className="loading">
                Wait for your picsures :)
            </div>
        </div>
    )
}

export  default MainPage;