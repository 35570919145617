import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./MainPage";
import ActiveAccountComponent from "./ActiveAccount";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path={'/'} element={<MainPage/>} />
            <Route path={"/account/active/:uuid/:secret"} Component={ActiveAccountComponent} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
